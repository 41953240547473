import "./scss/index.scss";
import * as React from "react";
import { MetaWrapper } from "../../components";
import Page from "./Page";
import { TypedHomePageQuery } from "./queries";

var View = function View() {
  return React.createElement("div", {
    className: "home-page"
  }, React.createElement(TypedHomePageQuery, {
    alwaysRender: true,
    displayLoader: false,
    errorPolicy: "all"
  }, function (_ref) {
    var data = _ref.data,
        loading = _ref.loading;
    return React.createElement(MetaWrapper, {
      meta: {
        description: data.shop ? data.shop.description : "",
        title: data.shop ? data.shop.name : ""
      }
    }, React.createElement(Page, {
      loading: loading,
      categories: data.categories,
      shop: data.shop
    }));
  }));
};

export default View;