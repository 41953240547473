import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React from "react";
import { FormattedMessage } from "react-intl";
import { RichTextContent } from "@components/atoms";
import * as S from "./styles";
var TABS;

(function (TABS) {
  TABS[TABS["DESCRIPTION"] = 0] = "DESCRIPTION";
  TABS[TABS["ATTRIBUTES"] = 1] = "ATTRIBUTES";
})(TABS || (TABS = {}));

export var ProductDescription = function ProductDescription(_ref) {
  var _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$descriptionJson = _ref.descriptionJson,
      descriptionJson = _ref$descriptionJson === void 0 ? "" : _ref$descriptionJson,
      attributes = _ref.attributes;

  var _React$useState = React.useState(TABS.DESCRIPTION),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      activeTab = _React$useState2[0],
      setActiveTab = _React$useState2[1];

  return React.createElement(S.Wrapper, null, React.createElement(S.Tabs, null, React.createElement(S.TabTitle, {
    active: activeTab === TABS.DESCRIPTION,
    onMouseEnter: function onMouseEnter(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.DESCRIPTION);
    },
    onClick: function onClick(evt) {
      evt.stopPropagation();
      setActiveTab(TABS.DESCRIPTION);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.3374163063",
    defaultMessage: "Description"
  }))), activeTab === TABS.DESCRIPTION && (descriptionJson ? React.createElement(RichTextContent, {
    descriptionJson: descriptionJson
  }) : React.createElement("p", null, description)));
};