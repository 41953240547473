import _defineProperty from "@babel/runtime/helpers/defineProperty";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { collectionUrl } from "@temp/app/routes";
import { generatePath, Link } from "react-router-dom";
import { Button } from "@components/atoms";
import { structuredData } from "../../core/SEO/Homepage/structuredData";
import heroImg from "../../images/seattle-hero.png";
import { ProductsFeatured } from "../../components";

var Page = function Page(_ref) {
  var shop = _ref.shop;
  var intl = useIntl();
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement("div", {
    className: "home-page__hero"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("div", {
    className: "home-page__hero-contents"
  }, React.createElement("div", {
    className: "home-page__hero-contents-image"
  }, React.createElement("img", {
    alt: "Hero",
    src: heroImg
  })), React.createElement("div", {
    className: "home-page__hero-contents-text"
  }, React.createElement("h1", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3123263053",
    defaultMessage: "Inspiring joy and connection with cardboard creations"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.4057090181",
    defaultMessage: "Our vibrant and colorful products are a joy to put together and behold!"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.3409556414",
    defaultMessage: "Whether you're looking for something to build as you relax, a wonderful gift sure to steal the show, or just a bit of eye-catching decor - it's hard to go wrong with a kit from Kiibo."
  })), React.createElement(Link, {
    to: generatePath(collectionUrl, {
      id: 1,
      slug: "featured"
    })
  }, React.createElement(Button, {
    fullWidth: true,
    color: "primary",
    testingContext: "shopNowButton"
  }, React.createElement(FormattedMessage, {
    id: "views.Home.Page.1396107634",
    defaultMessage: "Shop now"
  }))))))), React.createElement(ProductsFeatured, {
    title: intl.formatMessage(_defineProperty({
      defaultMessage: "Featured Products",
      "id": "views.Home.Page.1027650872"
    }, "id", "views.Home.Page.1027650872"))
  }));
};

export default Page;